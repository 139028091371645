import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <BrowserRouter >
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </BrowserRouter>
);

// amzon instance Ipaddress
// puplic Ipv4: 3.15.227.25
//  ssh-keygen -t ed25519 -C "aschumay03.ga@gmail.com"
// alter user 'root'@'localhost' identified with mysql_native_password by 'Abegarage@A24';
// nvm install v20.11.0

