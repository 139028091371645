import React from 'react';
// Import the Router from react-router ?
import { Routes, Route,  } from "react-router-dom";
import './App.css';
import AddEmployee from './pages/addemployee/AddEmployee';
import Login from './pages/login/Login';
import Home from './pages/home/Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/addemployee" element={<AddEmployee />} />
        <Route path="*" element={<div>404 page not found!</div>} />

      </Routes>
    </div>
  );
}

export default App;
